import { graphql } from "gatsby";
import React from "react";
import Layout from "../../components/layout/Layout";
import { Container, Row, Col } from "react-bootstrap";
import {
  useTranslation,
  Trans,
  I18nextContext,
} from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import * as EasydiagdpfStyles from "../../styles/easydiagdpf.module.css";
import useWindowSize from "../../components/useWindowSize";
import Seo from "../../components/seo";

export default function Easydiagdpf({ data }) {
  const { t } = useTranslation();
  const context = React.useContext(I18nextContext);
  const language = context.language;
  const { width } = useWindowSize();

  return (
    <Layout>
      <Seo description="Le diagnostic unique des filtres à particules. Le protocole de mesures assisté par ordinateur, lié à l’exploitation inédite des paramètres électriques, le tout combiné à une base de données, génère un rapport vulgarisé pour tous les usagers, de l’état de santé du filtre à particules." />
      <Container fluid className={EasydiagdpfStyles.Container}>
        {language === "fr" ? (
          width >= 992 ? (
            <Row className={EasydiagdpfStyles.Video}>
              <video loop autoPlay muted>
                <source
                  src={
                    require("../../videos/eddpf/eddpf_ultrawide.mp4").default
                  }
                  type="video/mp4"
                />
              </video>
            </Row>
          ) : (
            <Row className={EasydiagdpfStyles.Video}>
              <video loop autoPlay muted>
                <source
                  src={require("../../videos/eddpf/eddpf.mp4").default}
                  type="video/mp4"
                />
              </video>
            </Row>
          )
        ) : (
          <></>
        )}
        <Row>
          <Col xs={12} lg={{ span: 6, offset: 1 }} className="pt-4">
            <h1 className={EasydiagdpfStyles.Titre1}>
              <Trans i18nKey="ecosysteme.easydiag.savoirplus.h.9">
                <sup></sup>
              </Trans>
            </h1>

            <h2 className={EasydiagdpfStyles.Titre2}>
              {t("ecosysteme.easydiag.savoirplus.h.10")}
            </h2>
            <p className={EasydiagdpfStyles.Text}>
              <Trans i18nKey="ecosysteme.easydiag.savoirplus.p.5">
                a<span>b</span>
              </Trans>
            </p>
          </Col>
          <Col
            xs={12}
            lg={{ span: 4, offset: 1 }}
            className={EasydiagdpfStyles.ColImage}
          >
            <StaticImage
              className={EasydiagdpfStyles.GatsbyImage}
              src="../../images/savoirplus/eddpf.png"
              alt="eddpf"
              placeholder="BLURRED"
              formats={["AUTO", "WEBP"]}
              layout="fullWidth"
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

// export page query
export const query = graphql`
  query Easydiagdpf($language: String!) {
    projects: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { lang: { eq: $language } } }
    ) {
      nodes {
        html
        frontmatter {
          lang
          title
          stack
          slug
          thumb {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        id
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
